<template>
	<div>
		<bhLoading :show="loading" />
		<div style="padding:20px 0; background-color:#FFF;">
			<a-table :rowKey="(e) => e.id" :columns="columns" :dataSource="workflows" :loading="loading"
				class="white-table" :scroll="{ x: 1100 }">
				<div slot="status" slot-scope="workflow">
					{{ statusObj[workflow.status] || workflow.status || 'In Progress' }}
				</div>
				<div slot="currentStep" slot-scope="workflow">
					{{ workflow.current_step }}
				</div>
                <div slot="stoppedReason" slot-scope="workflow">
					<span v-if="workflow.completedAt">
						Contact Exited
					</span>
					<span v-else>
						{{ workflow.stoppedReason || '' }}
					</span>
				</div>
                <div slot="stoppedAt" slot-scope="workflow">
					{{ $formatDate(workflow.completedAt || workflow.stoppedAt, 'lll') }}
				</div>
				<div slot="startedAt" slot-scope="workflow" class="text-center">
					{{ $formatDate(workflow.startedAt, 'lll') }}
				</div>
				<div slot="updatedAt" slot-scope="workflow">
					{{ $formatDate(workflow.updatedAt, 'lll') }}
				</div>
				<div slot="actions" slot-scope="workflow" class="dF jSA">
					<a-button type="primary" ghost @click="viewJourney(workflow)">
						View Journey
					</a-button>
				</div>
			</a-table>
		</div>
		<a-modal v-if="selectedWorkflowProgress" v-model="journeyModal" @cancel="journeyModal = false" :footer="null"
			title="Lead Journey" :width="1000">
			<h5 class="mb-3">Workflow: <strong> {{ selectedWorkflowProgress.name }}</strong></h5>
			<a-card :bodyStyle="{ padding: 0 }">
				<a-table :rowKey="(e) => e.id" :columns="journeyColumns"
					:dataSource="leadJourney[selectedWorkflowProgress.id]" :loading="loading" class="white-table">
					<div slot="stepAction" slot-scope="workflow">
						{{
							marketingWorkflowSteps[workflow.marketing_workflow_step.action] || workflow.marketing_workflow_step.name
						}}
					</div>
					<div slot="stepDescription" slot-scope="workflow">
						{{ stepDescription(workflow.marketing_workflow_step) }}
					</div>
					<div slot="createdAt" slot-scope="workflow">
						{{ $formatDate(workflow.createdAt, 'lll') }}
					</div>
				</a-table>
			</a-card>
		</a-modal>
	</div>
</template>

<script>
	import bhLoading from 'bh-mod/components/common/Loading'
	import moment from 'moment'
	import { formatDate } from 'bh-mod'
	import workflowStepsMixin from '@/mixins/workflowStepsMixin'

	export default {
		components: {
			bhLoading
		},
		props: {
			contact: {
				type: Object,
				default: () => { }
			}
		},
		mixins: [workflowStepsMixin],
		computed: {
			workflows() {
				if (!this.contact.workflow_progresses) return [];
				return this.contact.workflow_progresses.map(w => {
					const { readableId, name, steps = [] } = this.marketingWorkflowList[w.marketing_workflow] || {}
					const current_step = steps.find(s => s.id === w.current_step);
					const description = this.stepDescription(current_step)

					return {
						...w,
						readableId,
						name,
						description,
						current_step: current_step ? this.marketingWorkflowSteps[current_step.action] : w.current_step
					}
				}) || [];
			},
		},
		data() {
			return {
				loading: false,
				journeyModal: false,
				selectedWorkflowProgress: null,
				leadJourney: {},
				journeyColumns: [
					{
						title: "Step Action",
						key: "action",
						scopedSlots: { customRender: "stepAction" },
						sorter: (a, b) => a.marketing_workflow_step.action < b.marketing_workflow_step.action ? -1 : a.marketing_workflow_step.action > b.marketing_workflow_step.action ? 1 : 0,
					},
					{
						title: "Step Description",
						key: "description",
						scopedSlots: { customRender: "stepDescription" },
					},
					{
						title: "Date Started",
						key: "createdAt",
						scopedSlots: { customRender: "createdAt" },
						sorter: (a, b) => a.createdAt - b.createdAt,
					},
				],
				columns: [
					{
						title: "ID",
						key: "readableId",
						dataIndex: "readableId",
						sorter: (a, b) => a.readableId < b.readableId ? -1 : a.readableId > b.readableId ? 1 : 0,
					},
					{
						title: "Name",
						key: "name",
						dataIndex: "name",
						sorter: (a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0,
					},
                    {
						title: "Date Started",
						key: "startedAt",
						scopedSlots: { customRender: "startedAt" },
						sorter: (a, b) => a.startedAt - b.startedAt,
					},
					{
						title: "Current Step",
						key: "current_step",
						scopedSlots: { customRender: "currentStep" },
						sorter: (a, b) => a.current_step < b.current_step ? -1 : a.current_step > b.current_step ? 1 : 0,
					},
					{
						title: "Step Description",
						key: "description",
						dataIndex: "description",
						sorter: (a, b) => a.description < b.description ? -1 : a.description > b.description ? 1 : 0,
					},
                    {
						title: "Status",
						key: "status",
						scopedSlots: { customRender: "status" },
						sorter: (a, b) => a.status < b.status ? -1 : a.status > b.status ? 1 : 0,
					},
                    {
						title: "Stopped Reason",
						key: "stoppedReason",
						scopedSlots: { customRender: "stoppedReason" },
						sorter: (a, b) => a.stoppedReason < b.stoppedReason ? -1 : a.stoppedReason > b.stoppedReason ? 1 : 0,
					},
					{
						title: "Stopped At",
						key: "stoppedAt",
						scopedSlots: { customRender: "stoppedAt" },
						sorter: (a, b) =>
							moment(a.stoppedAt).format("x") -
							moment(b.stoppedAt).format("x"),
					},
					{
						title: "Date Updated",
						key: "updatedAt",
						scopedSlots: { customRender: "updatedAt" },
						sorter: (a, b) =>
							moment(a.updatedAt).format("x") -
							moment(b.updatedAt).format("x"),
						defaultSortOrder: 'descend'
					},
					{
						title: "",
						key: "actions",
						scopedSlots: { customRender: "actions" },
					},
				],
				statusObj: {
					'in_progress': 'In Progress',
					'completed': 'Completed',
					'stopped': 'Stopped'
				},
			}
		},
		methods: {
			moment, formatDate,

			async viewJourney(workflowProgress) {
				this.selectedWorkflowProgress = workflowProgress
				if (this.leadJourney[workflowProgress.id]) {
					this.journeyModal = true
				} else {
					this.loading = true;
					try {
						const { data } = await this.$api.get(`contact-workflow-progress-journeys?contact=${this.contact.id}&marketing_workflow=${workflowProgress.marketing_workflow}`);
						const journey = data;
						if (workflowProgress.completedAt) {
							journey.push({
								marketing_workflow_step: {
									action: 'contact_exit'
								},
								createdAt: workflowProgress.completedAt
							})
						}
						this.leadJourney[workflowProgress.id] = data
						this.journeyModal = true;
					} catch (error) {
						this.$toastError(error, 'Error occurred while fetching lead journey for selected workflow')
					} finally {
						this.loading = false;
					}
				}
			},
		}
	}
</script>

<style scoped>
	.link {
		color: var(--orange);
	}

	.link:hover {
		text-decoration: underline;
		color: var(--orange);
	}
</style>
