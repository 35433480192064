<template>
    <div>
        <bhLoading :show="loading" />
        <EditDrawer @update="updateOpp" />
        <lostWonModal :opportunity="lostWon" @closed="closeLostWon" @won="updateWon" @lost="updateLost" />
        <a-card>
            <div class="dF jE" slot="title">
                <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                    <div slot="title">Add New Opportunity</div>
                    <a-icon @click="addOpportunity" style="font-size:25px; color:var(--orange); cursor:pointer" type="plus-circle" />
                </a-tooltip>
            </div>
            <template v-if="opportunities.length">
                <div :style="oppI != 0 ? 'padding-top:32px' :''" v-for="(opp, oppI) in opportunities" :key="opp.id + oppI" :value="opp.id + oppI">
                    <div class="dF aT jSB">
                        <div class="dF aT">
                            <div class="smallIcon mr-3" style="background-color: #FFDEAD;">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" width="15" height="15" fill="#F79425" data-icon="money-bill-alt" class="svg-inline--fa fa-money-bill-alt fa-w-20" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path  d="M352 288h-16v-88c0-4.42-3.58-8-8-8h-13.58c-4.74 0-9.37 1.4-13.31 4.03l-15.33 10.22a7.994 7.994 0 0 0-2.22 11.09l8.88 13.31a7.994 7.994 0 0 0 11.09 2.22l.47-.31V288h-16c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h64c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8zM608 64H32C14.33 64 0 78.33 0 96v320c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V96c0-17.67-14.33-32-32-32zM48 400v-64c35.35 0 64 28.65 64 64H48zm0-224v-64h64c0 35.35-28.65 64-64 64zm272 192c-53.02 0-96-50.15-96-112 0-61.86 42.98-112 96-112s96 50.14 96 112c0 61.87-43 112-96 112zm272 32h-64c0-35.35 28.65-64 64-64v64zm0-224c-35.35 0-64-28.65-64-64h64v64z"></path></svg>
                            </div>
                            <div>
                                <h6 style="color:var(--orange)">{{opp.name}}</h6>
                                <div class="dF aC">
                                    <div class="dF aC" v-if="opp.stage && opp.stage != ''">
                                        <div class="mr-2" style="color:var(--orange)">{{getStage(opp.stage)}}</div>
                                        <a-popover v-if="getStage(opp.stage) != 'Won'" placement="bottom" trigger='click' overlayClassName="popoverStyle">
                                            <div slot="content">
                                                <template v-for="stage in settings.options.stages.list">
                                                    <div v-if="stage.id != 'transaction'" @click="changeStage(opp.id, stage.id)" class="popoverContent" :key="stage.id" :value="stage.id">{{stage.name}}</div>
                                                </template>
                                            </div>
                                            <a-icon style="font-size:12px; cursor:pointer" class="mr-3" type="caret-down" />
                                        </a-popover>
                                        <div>|</div>
                                    </div>
                                    <div :class="opp.stage && opp.stage != '' ? 'ml-3':''">{{`$${$formatNumber(opp.dealValue)}`}}</div>
                                </div>
                                <div>{{`Estimated Opportunity Close Date: ${convertDate(opp.closeDate)}`}}</div>
                            </div>
                        </div>
                        <div class="dF">
                            <div class="dF mr-3">
                                <a-tooltip slot="extra" overlayClassName="change-tooltip-color" class="mr-2">
                                    <template slot="title">Quick View</template>
                                    <a-icon @click="$store.commit('OPEN_OPPORTUNITY_DETAILS',opp)" type="eye" class='icon-button' />
                                </a-tooltip>
                                <a-tooltip slot="extra" overlayClassName="change-tooltip-color" class="mr-2">
                                    <template slot="title">Edit Opportunity</template>
                                    <svg @click="editOpp(opp.id)" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 icon-button"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                </a-tooltip>
                                <a-tooltip slot="extra" overlayClassName="change-tooltip-color" class="mr-2">
                                    <template slot="title">Go to Sales Pipeline</template>
                                    <i @click="$router.push('/opportunities/sales')" style="color:#9EA0A5" class="fa fa-th-list icon-button" />
                                </a-tooltip>
                                <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                                    <template slot="title">Delete Opportunity</template>
                                    <i style="color:var(--danger)" @click="deleteOpp(opp.id)" class="fe fe-trash-2 icon-button" />
                                </a-tooltip>
                            </div>
                            <div class="dF">
                                <template v-for="(user,userI) in opp.owners">
                                    <div v-if="userI < 3" :key="userI" class="owner-icon" :style="userI !== 0? 'margin-left: -5px' : ''" style="cursor:pointer">
                                        <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                                            <template slot="title">{{`${user.firstName} ${user.lastName}`}}</template>
                                            <a-avatar v-if="user.avatar" :src="user.avatar" shape="circle" :size="30"/>
                                            <a-avatar v-else>{{user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase()}}</a-avatar>
                                        </a-tooltip>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
				This lead doesn't have any opportunities
			</template>
        </a-card>
    </div>
</template>

<script>
import EditDrawer from '@/components/contacts/EditDrawer'
import bhLoading from 'bh-mod/components/common/Loading'
import lostWonModal from '@/components/common/lostWonModal'

export default {
    components:{EditDrawer,bhLoading,lostWonModal},
    props:{
        id:{
            type:String,
            default:''
        }
    },
    data() {
        return{
            update:0,
            loading:false,
            lostWon:{
                type:'',
                visible:false,
                opportunity:{},
            },
        }
    },
    computed:{
		dateFormat(){
			return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.dateFormat ? this.$store.state.contacts.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
		},
        lostReasons() {
            if (this.$store.state.contacts?.allSettings?.app?.options?.lostReasons) return this.$store.state.contacts.allSettings.app.options.lostReasons
            else return {}
        },
        dealStages() {
            return this.$store.state.contacts.allSettings.app.options.stages.list
        },
        storeOpportunities() {
            return this.$store.state.contacts.opportunities || []
        },
        opportunities() {
            let opportunities = []
            let time = this.update
            if (this.storeOpportunities && this.storeOpportunities.length) {
				opportunities = this.storeOpportunities.filter(x => {
					if (x.contact != null && x.contact.id == this.id) {
						return true
					}
					if (x.secondaryContacts && x.secondaryContacts.length) {
						let ids = x.secondaryContacts.map(s => s.id)
						if (ids.includes(this.id)) {
							return true
						}
					}
				})
			}
            return opportunities
        },
        settings(){
            return this.$store.state.contacts.allSettings.app || {}
        },
        users() {
            let users = {}
            this.$store.state.contacts.users.forEach(user => {
                users[user.id] = user
            })
            return users
        }
    },
    methods:{
        async updateWon(obj,note){
            let opportunity = JSON.parse(JSON.stringify(obj))
            let newNote = JSON.parse(JSON.stringify(note))
            opportunity.stage = 'won'
            opportunity.probability = 100

            if (note.subject || note.content){
                await this.$api.put(`/opportunities/:instance/${opportunity.id}/note`,newNote).then( ({data}) => {
                    this.$store.commit('UPDATE_OPPORTUNITY_NOTES', data)
                    opportunity = data
                    opportunity.stage = 'won'
                    opportunity.probability = 100
                }).catch( err => {
                    console.error('ERORR', err)
                    if (err.response.data.error == 'Not Found'){
                        this.$message.error('Opportunity Not Found!')
                    }
                })
            }
            this.$api.put(`/opportunities/:instance/${opportunity.id}`,opportunity).then(async ({data}) => {
                this.$store.commit('UPDATE_OPPORTUNITY', data)

                await this.$api.post(`/timelines/:instance/opportunities/${opportunity.id}`, {date: new Date().getTime(), logNote:'This opportunity was marked as Won'})
                this.closeLostWon()
                this.updateOpp()
            }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        },
        async updateLost(obj){
            let opportunity = JSON.parse(JSON.stringify(obj))
            opportunity.stage = 'lost'
            opportunity.probability = 0

            let sendObj = {}
            if (opportunity.reason) {
                let find = this.lostReasons.find(x => x.id == opportunity.reason)
                let note = {
                    subject:'Lost Opportunity',
                    content:`Lost Reason: ${find.name}`
                }
                await this.$api.put(`/opportunities/:instance/${opportunity.id}/note`,note).then(({data}) => {
                    this.$store.commit('UPDATE_OPPORTUNITY_NOTES', data)
                    sendObj = data
                    sendObj.stage = 'lost'
                    sendObj.probability = 0
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
            }
            else {
                sendObj = opportunity
            }

            this.$api.put(`/opportunities/:instance/${opportunity.id}`,sendObj).then(async ({data}) => {
                this.$store.commit('UPDATE_OPPORTUNITY', data)

                this.$api.post(`/timelines/:instance/opportunities/${data.id}`, {date: new Date().getTime(), logNote:'This opportunity was marked as Lost'})
                this.closeLostWon()
                this.updateOpp()
            }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        },
        closeLostWon(){
            this.lostWon = {
                type:'',
                visible:false,
                opportunity:{}
            }
        },
        markWon(obj){
            this.lostWon = {
                type:'won',
                visible: true,
                opportunity:JSON.parse(JSON.stringify(obj))
            }
        },
        markLost(obj){
            this.lostWon = {
                type:'lost',
                visible: true,
                opportunity:JSON.parse(JSON.stringify(obj))
            }
        },
        updateOpp() {
            this.update = Date.now()
        },
        editOpp(id) {
            this.$router.push(`/opportunities/sales/${id}`)
            this.$store.commit('CLOSE_OPPORTUNITY_DETAILS')
        },
        deleteOpp(id) {
            if (this.$p < 40){
                return this.$message.error('You do not have permission to delete Opportunities')
            }
            let index = this.opportunities.findIndex(x => x.id == id)
            let name = ''
            if (index != -1) name = this.opportunities[index].name
            let self = this
            this.$confirm({
                title: "Delete Opportunity",
                content: h => <div>Do you want to delete <b>{name}</b>? All information related to them will be lost.</div>,
                okText: 'Delete',
                okType: 'danger',
                cancelText: 'Cancel',
                centered: true,
                onOk() {
                    self.loading = true
                    self.$api.delete(`/opportunities/:instance/${id}`).then( ({data}) => {
                        self.loading = false
                        self.$store.commit('UPDATE_OPPORTUNITIES', data)
                    }).catch(err => {
						self.loading = false
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err))
						}
					})
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        },
        changeStage(oppId, stageId) {
            let index
            index = this.opportunities.findIndex(x => x.id == oppId)
            if (index != -1) {
                let sendObj = {}
                sendObj = JSON.parse(JSON.stringify(this.opportunities[index]))
                sendObj.stage = stageId
                let find = this.dealStages.find(x => x.id == stageId)
                if (find && find.id == 'won'){
                    this.markWon(sendObj)
                } else if (find && find.id == 'lost'){
                    this.markLost(sendObj)
                } else {
                    if (find && find.probability) sendObj.probability = find.probability
                    this.$api.put(`/opportunities/:instance/${sendObj.id}`, sendObj).then( ({data}) => {
                        this.$store.commit('UPDATE_OPPORTUNITY', data)
                        this.update = Date.now()
                    }).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					})
                }
            }
        },
        convertDate(num){
			let x = new Date(num)
			//Tue Oct 06 2020 12:11:07 GMT-0400 (Eastern Daylight Time)
			let year = x.getFullYear()
			let month = x.getMonth()+1
			let day = x.getDate()
			if (this.dateFormat == 'YYYY/MM/DD') return year+'/'+month+'/'+day
			else if (this.dateFormat == 'DD/MM/YYYY') return day+'/'+month+'/'+year
			else {
				return month+'/'+day+'/'+year
			}
		},
        getStage(stageId) {
            if (this.settings.options && this.settings.options.stages && this.settings.options.stages.list && this.settings.options.stages.list.length) {
                let find = this.settings.options.stages.list.find(x => x.id == stageId)
                if (find) return find.name
                else return ''
            }
        },
        addOpportunity() {
            this.$store.commit('ADD_NEW', 'Opportunity')
            this.$store.commit('ADD_LEAD_ACTION', this.id)
        },
    }
}
</script>

<style scoped>
.icon-button{
    cursor: pointer;
}
.icon-button:hover{
    color:var(--orange) !important;
}
.smallIcon {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    text-align: center;
    padding: 7px 0;
}
.popoverContent{
    height: 35px;
    width: 150px;
    line-height: 35px;
    padding-left: 10px;
    cursor: pointer;
}
.popoverContent:hover{
    background-color: var(--off-white-light);
    color:black;
}
</style>
<style>
.change-tooltip-color .ant-tooltip-content .ant-tooltip-inner{
    background:#3F3356;
}
</style>
