<template>
    <div>
        <EditDrawer />
        <bhLoading :show="loading" />
        <a-card v-if="id">
            <div class="dF jE" slot="title">
                <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                    <div slot="title">Add New Note</div>
                    <a-icon @click="addNote" style="font-size:25px; color:var(--orange); cursor:pointer" type="plus-circle" />
                </a-tooltip>
            </div>
            <template v-if="notes && notes.length">
                <div :style="noteI != 0 ? 'padding-top:32px' :''" v-for="(note, noteI) in notes" :key="note.id + noteI" :value="note.id + noteI">
                    <div v-if="note.id" class="dF aT jSB">
                        <div class="dF aT">
                            <div class="smallIcon mr-3" style="background-color: #FFDEAD;">
                                <svg width="12" height="12" viewBox="0 0 20 20" fill="#F79425"><path id="Icon_material-comment" data-name="Icon material-comment" d="M22.99,5A2,2,0,0,0,21,3H5A2.006,2.006,0,0,0,3,5V17a2.006,2.006,0,0,0,2,2H19l4,4ZM19,15H7V13H19Zm0-3H7V10H19Zm0-3H7V7H19Z" transform="translate(-3 -3)"/> </svg>
                            </div>
                            <div class="f1">
                                <h6 style="color:var(--orange)">{{`Subject: ${note.subject}`}}</h6>
                                <div style="color:#000">{{note.content}}</div>
                                <div>{{convertDate(note.updatedAt)}}</div>
                            </div>
                        </div>
                        <div class="dF">
                            <div class="dF mr-3">
                                <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                                    <template slot="title">Quick View</template>
                                    <a-icon class="icon-button mr-2" type="eye" @click="viewNote(note)" />
                                </a-tooltip>
                                <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                                    <template slot="title">Edit Note</template>
                                    <svg @click="editNote(note.id)" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-2 icon-button"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                </a-tooltip>
                                <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                                    <template slot="title">Delete Note</template>
                                    <i style="color:var(--danger); cursor:pointer" @click="deleteNote(note.id)" class="fe fe-trash-2" />
                                </a-tooltip>
                            </div>
                            <div v-if="validUser.includes(note.user)">
                                <a-tooltip slot="extra" overlayClassName="change-tooltip-color" style="cursor:pointer">
                                    <template slot="title">{{`${getUser(note.user).firstName} ${getUser(note.user).lastName}`}}</template>
                                    <a-avatar v-if="getUser(note.user) && getUser(note.user).avatar" :src="getUser(note.user).avatar" shape="circle" :size="30"/>
                                    <a-avatar v-else-if="getUser(note.user).firstName && getUser(note.user).lastName">{{getUser(note.user).firstName[0].toUpperCase() + getUser(note.user).lastName[0].toUpperCase()}}</a-avatar>
                                </a-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
			<template v-else>
				This lead doesn't have any notes
			</template>
        </a-card>
    </div>
</template>

<script>
import bhLoading from 'bh-mod/components/common/Loading'
import EditDrawer from '@/components/contacts/EditDrawer'
import moment from 'moment'

export default {
    components:{bhLoading,EditDrawer},
    props:{
        id:{
            type:String,
            default:''
        }
    },
    data(){
        return{
            notes:[],
            loading:false,
        }
    },
    watch: {
        visible:{
            handler(val) {
                if (!val && this.id) {
                    if (this.contacts[this.id]) {
                        if ((this.contacts[this.id].notes && this.contacts[this.id].notes.length) != (this.notes && this.notes.length)) {
                            if (typeof this.contacts[this.id].notes[0] == 'object') {
                                this.notes = this.contacts[this.id].notes.sort((a,b) => {
                                    return (this.moment(b.updatedAt).format('X')*1000) - (this.moment(a.updatedAt).format('X')*1000)
                                })
                            }
                        }
                    } else if (this.$store.state.contacts.externalContacts && this.$store.state.contacts.externalContacts[this.id]) {
                        if ((this.$store.state.contacts.externalContacts[this.id].notes && this.$store.state.contacts.externalContacts[this.id].notes.length) != (this.notes && this.notes.length)) {
                            if (typeof this.$store.state.contacts.externalContacts[this.id].notes[0] == 'object') {
                                this.notes = this.$store.state.contacts.externalContacts[this.id].notes.sort((a,b) => {
                                    return (this.moment(b.updatedAt).format('X')*1000) - (this.moment(a.updatedAt).format('X')*1000)
                                })
                            }
                        }
                    }
                }
            }
        },
        EditVisible:{
            handler(val) {
                if(!val) {
                    console.log('hello this runs', this.id)
                    if (this.contacts[this.id]) {
                        if (this.contacts[this.id].notes && this.contacts[this.id].notes.length) {
                            if (typeof this.contacts[this.id].notes[0] == 'object') {
                                this.notes = this.contacts[this.id].notes.sort((a,b) => {
                                    return (this.moment(b.updatedAt).format('X')*1000) - (this.moment(a.updatedAt).format('X')*1000)
                                })
                            }
                        }
                    } else if (this.$store.state.contacts.externalContacts && this.$store.state.contacts.externalContacts[this.id]) {
                        if (this.$store.state.contacts.externalContacts[this.id].notes && this.$store.state.contacts.externalContacts[this.id].notes.length) {
                            if (typeof this.$store.state.contacts.externalContacts[this.id].notes[0] == 'object') {
                                this.notes = this.$store.state.contacts.externalContacts[this.id].notes.sort((a,b) => {
                                    return (this.moment(b.updatedAt).format('X')*1000) - (this.moment(a.updatedAt).format('X')*1000)
                                })
                            }
                        }
                    }
                }
            }
        }
    },
    computed:{
		dateFormat(){
			return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.dateFormat ? this.$store.state.contacts.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
		},
        validUser() {
            return this.users.map(x => x.id)
        },
        EditVisible() {
            return this.$store.state.contacts.editDrawer.visible
        },
        visible() {
            return this.$store.state.addNew.visible
        },
        users() {
            return this.$store.state.contacts.users || []
        },
        storeUser () {
            return this.$store.state.contacts.allContacts[this.id] || {}
        },
        contacts() {
            if (this.$store.state.contacts && this.$store.state.contacts.allContacts) return this.$store.state.contacts.allContacts
            else return {}
        },
        externalContacts() {
            return this.$store.state.contacts.externalContacts || {}
        }
    },
    methods:{
        viewNote(note) {
            this.$store.commit('OPEN_PREVIEW_MODAL', {type:'note', object:note})
        },
        moment,
        getUser(id) {
            if (typeof id == 'string') {
                let find = this.users.find(x => {
					if (x && x.id){
						return x.id == id
					} else return false
				})
                if (find) return find
                else return {}
            } else if (typeof id == 'object') {
                return id
            }
        },
        addNote(){
            this.$store.commit('ADD_NEW', 'Note')
            this.$store.commit('ADD_LEAD_ACTION', this.id)
        },
        convertDate(date) {
            let year = date.substring(0,4)
            let month = date.substring(5,7)
            let day = date.substring(8,10)
            if (this.dateFormat == 'YYYY/MM/DD') return `${year}/${month}/${day}`
			else if (this.dateFormat == 'DD/MM/YYYY') return `${day}/${month}/${year}`
			else {
				return `${month}/${day}/${year}`
			}
        },
        editNote(id) {
            this.$store.commit('OPEN_EDIT_DRAWER', {type:'note', id:id})
            console.log('editnote',id)
        },
        deleteNote(id) {
            let self = this
            this.$confirm({
                title: "Delete Note",
                content: h => <div>Do you want to delete this Note?</div>,
                okText: 'Delete',
                okType: 'danger',
                cancelText: 'Cancel',
                centered: true,
                onOk() {
                    self.loading = true
                    self.$api.delete(`/contacts/:instance/${self.id}/${id}/note`).then( ({data}) => {
                        console.log('just delete', data)
                        self.loading = false
                        if (data.success) {
                            let note = self.notes.find(x => x.id == id)
                            if (note) {
                                self.$store.commit('DELETE_NOTE', note)
                                self.$emit('update')
                            }
                        }
                    }).catch(err => {
						self.loading = false
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err))
						}
					})
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        }
    },
    created() {
        let exist = false
		let existExternal = false
        if (this.contacts[this.id]) exist = true
        if (this.externalContacts[this.id]) existExternal = true
        if (!exist && !existExternal){
            this.loading = true
            this.$api.get(`/contacts/:instance/${this.id}/notes`).then( ({data}) => {
                data.sort((a,b) => {
                    return (this.moment(b.updatedAt).format('X')*1000) - (this.moment(a.updatedAt).format('X')*1000)
                })
				console.log('WHAT IS THE ID?', this.id)
                this.$store.commit('UPDATE_CONTACT_NOTES',{id:this.id, notes:data})
                this.notes = data
				console.log('THIS NOTES', this.notes)
                this.loading = false
            }).catch(err => {
				this.loading = false
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        } else if (exist) {
			console.log('EXIST RAN', this.contacts[this.id])
			this.notes = this.contacts[this.id].notes
			console.log('THIS NOTES', this.notes)
		} else if (existExternal) {
			console.log('EXTERNAL RAN')
			this.notes = this.externalContacts[this.id].notes
		}
    }
}
</script>

<style scoped>
.icon-button{
    cursor: pointer;
}
.icon-button:hover{
    color:var(--orange)
}
.smallIcon {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    text-align: center;
    padding: 7px 0;
}
</style>
